import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AirportWidget from '../components/AirportWidget/AirportWidget';
import CurrencyWidget from '../components/CurrencyWidget/CurrencyWidget';
import PromotionWidget from '../components/PromotionWidget/PromotionWidget';
import RestaurantWidget from '../components/RestaurantWidget/RestaurantWidget';
import TransportationWidget from '../components/TransportationWidget/TransportationWidget';
import UsimWidget from '../components/UsimWidget/UsimWidget';
import WeatherDashboard from '../components/WeatherWidget/WeatherWidget';

const RouterConfig = () => {
  return (
    <Router>
      <Routes>
        <Route path="/:city" 
          element={
            <>
            <WeatherDashboard />

            <div style={{ marginBottom: '32px'}} />
            
            <CurrencyWidget />

            <div style={{ marginBottom: '32px'}} />

            <AirportWidget />

            <div style={{ marginBottom: '32px'}} />

            <UsimWidget />

            <div style={{ marginBottom: '32px'}} />

            <TransportationWidget />

            <div style={{ marginBottom: '32px'}} />

            <RestaurantWidget/>


            <div style={{ marginBottom: '32px'}} />

            <PromotionWidget/>
            </>
          } 
        />
      </Routes>
    </Router>
  );
}

export default RouterConfig;
