import React, {useState, useEffect} from 'react';
import { DateTime } from 'luxon';
import { Card, CardContent, Typography, Grid } from "@mui/material";
import { useParams } from 'react-router-dom';

// import WeatherIcon from './WeatherIcon';
// import weatherService from '../../services/weatherService';


// Helper functions
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const cityTimeZones = {
  'fukuoka': 'Asia/Tokyo',
  'osaka': 'Asia/Tokyo',
  'tokyo': 'Asia/Tokyo',
  'sapporo': 'Asia/Tokyo',
  'okinawa': 'Asia/Tokyo',
  'suwon': 'Asia/Seoul',
}

const WeatherDashboard = () => {
  const [currentTime, setCurrentTime] = useState('');
  // const [weatherData, setWeatherData] = useState({ temp: null, weather_id: null});
  const { city } = useParams();

  // For fetching weather data
  // useEffect(() => {
  //   weatherService.getWeatherByCity(city)
  //     .then(data => {
  //       setWeatherData({
  //         temp: data.temp,
  //         weather_id: data.weather_id
  //       });
  //     })
  //     .catch(error => {

  //     });
  // }, [city]);

  // For updating current time
  useEffect(() => {
    const timeZoneForCity = cityTimeZones[city] || 'Asia/Tokyo';
    setCurrentTime(DateTime.now().setZone(timeZoneForCity).toLocaleString(DateTime.TIME_24_SIMPLE));

    const interval = setInterval(() => {
      setCurrentTime(DateTime.now().setZone(timeZoneForCity).toLocaleString(DateTime.TIME_24_SIMPLE));
    }, 60000);
    
    return () => clearInterval(interval);
  }, [city]);

  return (
    <WeatherWidget
      currentTime={currentTime}
      // temperature={weatherData.temp}
      // weatherId={weatherData.weather_id}
      cityName={city}
    />
  )
}

const WeatherWidget = ({currentTime, temperature, weatherId, cityName}) => {
    return (
        <Grid container justifyContent="center">
            <Grid item xs={12} sm={6} md={6} lg={6}>
                <div style={{ background: 'white', borderRadius: '25%' }}>
                  <Card style={{ padding: '12px' }}>
                    <CardContent>
                        <Grid container alignItems="center" justifyContent="space-between">
                            <Grid item>
                                <Typography variant="h5" component="div" style={{ fontWeight: 'bold' }}>
                                    {capitalizeFirstLetter(cityName)}
                                </Typography>
                                <Typography variant="h6" component="div" style={{ color: 'gray' }}>
                                    {currentTime}
                                </Typography>
                            </Grid>
                            
                            {/* <Grid item style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <WeatherIcon
                                  weather_id={weatherId}
                                />
                                
                                <Typography variant="h3" component="span" style={{ fontWeight: 'bold', marginLeft: '16px' }}>
                                    {temperature}
                                    <Typography variant="h5" component="span" style={{ fontWeight: 'bold', verticalAlign: 'top', marginLeft: '4px' }}>
                                      °C
                                    </Typography>
                                </Typography>
                            </Grid> */}

                        </Grid>
                    </CardContent>
                  </Card>
                </div>
            </Grid>
        </Grid>
    );
};

export default WeatherDashboard;

