import React from "react";
import { Card, Typography, Grid, Avatar } from "@mui/material";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const AirportWidget = () => {
    return (
        <Grid container justifyContent="center">
            <Grid container item xs={12} sm={6} md={6} lg={6} spacing={1.5}>

            <Grid item xs={6} sm={6} md={6} lg={6}>
                <div
                    style={{ 
                        background: 'white', 
                        display: 'flex', 
                        alignItems: 'center', 
                        justifyContent: 'space-between',
                        borderRadius: '25%'
                    }} 
                    onClick={() => window.open("https://objekt.notion.site/b83df28757d74eccbcc9b9e8bc54d7f7?pvs=4", "_blank")}
                >
                    <Card style={{ padding: '16px 4px 16px 24px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}> 
                            <Avatar style={{ backgroundColor: '#E0E0E0'}}>
                                🛂
                            </Avatar>
                            <div style={{marginBottom: '8px'}}/>
                            <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                                입국 준비
                            </Typography>
                        </div>
                        <ChevronRightIcon style={{ fontSize: 32 }}/>
                    </Card>
                </div>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6}>
                <div
                    style={{ 
                        background: 'white', 
                        display: 'flex', 
                        alignItems: 'center', 
                        justifyContent: 'space-between',
                        borderRadius: '25%'
                    }} 
                    onClick={() => window.open("https://objekt.notion.site/62fcab90279b4c078219c0e902e23bbe?pvs=4", "_blank")}
                >
                    <Card style={{ padding: '16px 4px 16px 24px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}> 
                            <Avatar style={{ backgroundColor: '#E0E0E0' }}>
                                🛄
                            </Avatar>
                            <div style={{marginBottom: '8px'}}/>
                            <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                                수화물 규정
                            </Typography>
                        </div>
                        <ChevronRightIcon style={{ fontSize: 32 }}/>
                    </Card>
                </div>
            </Grid>
            </Grid>
        </Grid>
    )
}

export default AirportWidget