import React from "react";
import { Card, Typography, Grid, Avatar } from "@mui/material";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useParams } from "react-router-dom";

const cityLinks = {
    "fukuoka": "https://objekt.notion.site/60ee813fd960440fa437745423a96b78?pvs=4",
    "osaka": "https://objekt.notion.site/11bc43510823420197f0100de00be57c?pvs=4",
    "tokyo": "https://objekt.notion.site/15657329fa73444e9bd2fc23f24778fd?pvs=4",
    "sapporo": "https://objekt.notion.site/84467b8e83dc46f3ac1b9640efabb3ee?pvs=4",
    "okinawa": "https://objekt.notion.site/beaf8becdd70487fa4a6238d0e604b40?pvs=4"
};

const TransportationWidget = () => {
    const { city } = useParams();

    return (
        <Grid container justifyContent="center">
            <Grid item xs={12} sm={6} md={6} lg={6} >
                <div
                    style={{ 
                        background: 'white', 
                        display: 'flex', 
                        alignItems: 'center', 
                        justifyContent: 'space-between',
                        borderRadius: '25%'
                    }} 
                    onClick={() => window.open(cityLinks[city], "_blank")}
                >
                    <Card style={{ padding: '16px 24px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}> 
                            <Avatar style={{ backgroundColor: '#E0E0E0', marginRight: '16px' }}>
                                🚇
                            </Avatar>
                            <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                                교통패스 정리
                            </Typography>
                        </div>
                        <ChevronRightIcon style={{ fontSize: 32 }}/>
                    </Card>
                </div>
            </Grid>
        </Grid>
    )
}

export default TransportationWidget;