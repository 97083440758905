import React from "react";
import { Card, Typography, Grid, Avatar } from "@mui/material";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const UsimWidget = () => {
    return (
        <Grid container justifyContent="center">
            <Grid item xs={12} sm={6} md={6} lg={6} >
                <div
                    style={{ 
                        background: 'white', 
                        display: 'flex', 
                        alignItems: 'center', 
                        justifyContent: 'space-between',
                        borderRadius: '25%'
                    }} 
                    onClick={() => window.open("https://objekt.notion.site/bcb5ea86768b49f19980e1a257af202a?pvs=4", "_blank")}
                >
                    <Card style={{ padding: '16px 24px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}> 
                            <Avatar style={{ backgroundColor: '#E0E0E0', marginRight: '16px' }}>
                                📶
                            </Avatar>
                            <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                                유심
                            </Typography>
                        </div>
                        <ChevronRightIcon style={{ fontSize: 32 }}/>
                    </Card>
                </div>
            </Grid>
        </Grid>
    )
}

export default UsimWidget;