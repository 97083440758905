import React from "react";
import { Card, Typography, Grid, Avatar } from "@mui/material";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useParams } from "react-router-dom";

const cityLinks = {
    "fukuoka": "https://objekt.notion.site/aa3c85ea3e484713801ff28540131001?pvs=4",
    "osaka": "https://objekt.notion.site/695faa8e6d584718b96ba79d6dbdfec7?pvs=4",
    "tokyo": "https://objekt.notion.site/84fd7a41871340108a400d68dc7aadf0?pvs=4",
    "sapporo": "https://objekt.notion.site/305d7c6272874c3285db87408b558f30?pvs=4",
    "okinawa": "https://objekt.notion.site/d9cf390160b54855aad7746e48ec59a7?pvs=4"
};

const RestaurantWidget = () => {
    const { city } = useParams();

    return (
        <Grid container justifyContent="center">
            <Grid item xs={12} sm={6} md={6} lg={6} >
                <div
                    style={{ 
                        background: 'white', 
                        display: 'flex', 
                        alignItems: 'center', 
                        justifyContent: 'space-between',
                        borderRadius: '25%'
                    }} 
                    onClick={() => window.open(cityLinks[city], "_blank")}
                >
                    <Card style={{ padding: '16px 24px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}> 
                            <Avatar style={{ backgroundColor: '#E0E0E0', marginRight: '16px' }}>
                                🥘
                            </Avatar>
                            <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                                맛집 정리
                            </Typography>
                        </div>
                        <ChevronRightIcon style={{ fontSize: 32 }}/>
                    </Card>
                </div>
            </Grid>
        </Grid>
    )
}

export default RestaurantWidget;