import React, { useState } from "react";
import { Card, CardContent, Typography, Grid, TextField, InputAdornment, Select, MenuItem, Avatar } from "@mui/material";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { useParams } from 'react-router-dom';

const cityCurrencies = {
    'fukuoka': { symbol: '¥', name: 'JPY' },
    'tokyo': { symbol: '¥', name: 'JPY' },
    'osaka': { symbol: '¥', name: 'JPY' },
    'sapporo': { symbol: '¥', name: 'JPY' },
    'okinawa': { symbol: '¥', name: 'JPY' },
    'suwon': { symbol: '₩', name: 'KRW' },
};

const conversionRates = {
    'JPY': { 'USD': 0.0067, 'EUR': 0.0063, 'KRW': 9.01 },
    'KRW': { 'USD': 0.00075, 'EUR': 0.00070, 'JPY': 0.11 },
};

const currencySymbols = {
    'USD': '$',
    'EUR': '€',
    'KRW': '₩'
};

const CurrencyWidget = () => {
    const { city } = useParams();
    
    const [primaryCurrencyValue, setPrimaryCurrencyValue] = useState('');
    const [secondaryCurrencyValue, setSecondaryCurrencyValue] = useState('');
    const [selectedCurrency, setSelectedCurrency] = useState('KRW');

    const handlePrimaryCurrencyChange = (e) => {
        const value = parseFloat(e.target.value);
        setPrimaryCurrencyValue(e.target.value);
        const secondaryValue = (value * (conversionRates[cityCurrencies[city].name][selectedCurrency] || 1)).toFixed(2);
        setSecondaryCurrencyValue(secondaryValue);
    };
    
    const handleSecondaryCurrencyChange = (e) => {
        const value = parseFloat(e.target.value);
        setSecondaryCurrencyValue(e.target.value);
        const primaryValue = (value / (conversionRates[cityCurrencies[city].name][selectedCurrency] || 1)).toFixed(2);
        setPrimaryCurrencyValue(primaryValue);
    };
    
    const handleCurrencySelection = (e) => {
        const currency = e.target.value;
        setSelectedCurrency(currency);
        const secondaryValue = (primaryCurrencyValue * conversionRates[cityCurrencies[city].name][currency]).toFixed(2);
        setSecondaryCurrencyValue(secondaryValue);
    };
    
    return (
        <>
        <Grid container justifyContent="center">
            <Grid item xs={12} sm={6} md={6} lg={6}>
                <div style={{ background: 'white', borderRadius: '25%' }}>
                  <Card style={{ padding: '12px' }}>
                    <CardContent>
                        <Grid container spacing={2} direction="column" alignItems="center">
                            <Grid container item alignItems='center' justifyContent="space-between">
                                <Grid item>
                                    <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                                    {cityCurrencies[city].name}
                                    </Typography>
                                </Grid>

                                {/* Textfield */}
                                <Grid item xs={7}>
                                    <TextField
                                        variant="standard"
                                        value={primaryCurrencyValue}
                                        onChange={handlePrimaryCurrencyChange}
                                        InputProps={{
                                            startAdornment: 
                                                <InputAdornment position="start">
                                                    <span style={{fontSize: '1.2rem'}}>{cityCurrencies[city].symbol}</span>
                                                </InputAdornment>
                                        }}
                                        inputProps={{
                                            style: { fontSize: "1.5rem", textAlign: "right" }
                                        }}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>

                            {/* Dropdown and Textfield */}
                            <Grid container item alignItems='center' justifyContent="space-between">
                                <Grid container item alignItems="center" justifyContent="space-between">

                                    <Grid item>
                                        <Select
                                            variant="standard"
                                            value={selectedCurrency}
                                            onChange={handleCurrencySelection}
                                            style={{ fontWeight: 'bold', fontSize: '1.2rem' }}
                                        >
                                            <MenuItem value="KRW">KRW</MenuItem>
                                            <MenuItem value="USD">USD</MenuItem>
                                            <MenuItem value="EUR">EUR</MenuItem>
                                        </Select>
                                    </Grid>

                                    {/* Currency input */}
                                    <Grid item xs={7}>
                                        <TextField
                                            variant="standard"
                                            value={secondaryCurrencyValue}
                                            onChange={handleSecondaryCurrencyChange}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">
                                                    <span style={{ fontSize: '1.2rem' }}>
                                                    {currencySymbols[selectedCurrency] || ''}
                                                    </span>
                                                </InputAdornment>,
                                            }}
                                            inputProps={{
                                                style: { fontSize: "1.5rem", textAlign: "right" }
                                            }}
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                  </Card>
                </div>
            </Grid>
        </Grid>
        <div style ={{marginBottom: '12px'}}/>
        <CurrencyWidgetHeader />
        </>
    );
};

const CurrencyWidgetHeader = () => {
    return (
        <Grid container justifyContent="center">
            <Grid item xs={12} sm={6} md={6} lg={6} >
                <div
                    style={{ 
                        background: 'white', 
                        display: 'flex', 
                        alignItems: 'center', 
                        justifyContent: 'space-between',
                        borderRadius: '25%'
                    }} 
                    onClick={() => window.open("https://objekt.notion.site/ef6317fb7a0d43b6baab276d7daa2477?pvs=4", "_blank")}
                >
                    <Card style={{ padding: '16px 24px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}> 
                            <Avatar style={{ backgroundColor: '#E0E0E0', marginRight: '16px' }}>
                                💹
                            </Avatar>
                            <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                                환전
                            </Typography>
                        </div>
                        <ChevronRightIcon style={{ fontSize: 32 }}/>
                    </Card>
                </div>
            </Grid>
        </Grid>
    )
}

export default CurrencyWidget;